
import { defineComponent, ref, unref, toRefs, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { useUpdatePayee, usePayee } from '@/composables/api';
import { PartialPayee } from '@/interfaces/Payee';
import useFormErrors from '@/utils/validate';
import { normalizeRequestData } from '@/utils/normalization';
import { ResponseError, UPDATED_SUCCESSFULLY_TEXT } from '@/apis';
import { Rule } from 'async-validator';

const DEFAULT_FORM_VALUES: PartialPayee & {
  } = {
    receiveBankCode: '',
    receiveBankAccount: '',
    receiveName: '',
    receivePhone: '',
    receiveAddress: ''
  };

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const router = useRouter();
    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const isFormLoading = ref(true);

    const { isLoading: isUpdating, mutate: update } = useUpdatePayee();
    const { formErrors } = useFormErrors();

    const { data, dataUpdatedAt } = usePayee({
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    const REQUIRED_RULE: Rule = {
      required: true,
      message: '此為必填欄位'
    };

    const rules = {
      receiveBankCode: [REQUIRED_RULE],
      receiveBankAccount: [REQUIRED_RULE],
      receiveName: [REQUIRED_RULE]
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update(
              {
                data: normalizeRequestData(formValues.value)
              },
              {
                onSuccess() {
                  ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

                  router.go(-1);
                },
                onError(error: ResponseError) {
                  ElMessage.error(error.response?.data.error.message);
                  formErrors.value = error.response?.data.error.message;
                }
              }
            );
          }
        }
      });
    };

    onMounted(() => {
      if (!isEdit.value) {
        isFormLoading.value = false;
      }
    });

    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;

      isFormLoading.value = false;
    });

    return {
      data,
      rules,
      formRef,
      formValues,
      formErrors,
      isFormLoading,
      isUpdating,
      submitForm
    };
  }
});
